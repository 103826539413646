.listContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.list {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.titleAndInfo {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.iconContainer {
  border-radius: 8px;
  background: var(--Base-300);
  min-width: 56px;
  max-width: 56px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconContainer img {
  max-width: 32px;
  width: 100%;
}
@media only screen and (max-width: 520px) {
  .iconContainer {
    min-width: 46px;
    max-width: 46px;
  }
  .iconContainer img {
    max-width: 25px;
    width: 100%;
  }
}
