.marketer {
  border-radius: 20px;
  border: 1px solid var(--Base-300);
  background: var(--Base-400);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 0;
  border-bottom: 1px solid var(--Base-300);
}
.itemContainer:last-child {
  border: none;
}
@media only screen and (max-width: 991px) {
  .marketer {
    padding: 12px;
  }
}
@media only screen and (max-width: 380px) {
  .marketer {
    padding: 12px 10px;
  }
}
