.wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}

.count {
  border-radius: 6px;
  background: var(--Base-500);
  padding: 5px 5px;
  min-width: 36px;
}

@media only screen and (max-width: 520px) {
}
@media only screen and (max-width: 380px) {
}
