.card {
  display: grid;
  grid-template-columns: 1fr 1fr auto auto;
  gap: 32px;
  align-items: center;

  border-radius: 25px;
  position: relative;
  background: var(--Base-400);
  padding: 20px;
}
.bannerContainer,
.headingAndInfoContainer,
.itemContainer,
.bottom {
  position: relative;
}
.banner {
  width: 100%;
  min-height: 143px;
  object-fit: cover;
  object-position: center;
  border-radius: 25px 25px 0 0;
  display: block;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-top: 10px;
}
.icon {
  max-width: 80px;
  width: 100%;
  margin-top: -50px;
}
.eth {
  width: 26px;
  border-radius: 50%;
}
.features {
  display: flex;
  align-items: center;
  gap: 4px;
}
.feature {
  border-radius: 6px;
  background: var(--Base-200);

  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.lock {
  font-size: 16px;
  color: var(--Base-100);
}
.status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  padding: 4px 8px;
  margin-left: auto;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 6px;
}
.statusImg {
  width: 14px;
}
.upcoming {
  background: var(--Secondary-Default);
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  min-width: 200px;
}
.item {
  display: flex;
  justify-content: space-between;

  align-items: center;
  gap: 20px;
  border-radius: 6px;
  border: 1.168px solid var(--Base-300);
  background: var(--Base-400);
  padding: 8px;
}
.headingAndInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.progressContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom {
  display: flex;

  justify-content: space-between;
  flex-direction: column;
  gap: 50px;
  min-width: 150px;
  height: 100%;
}
.icons {
  display: flex;
  align-items: center;
  gap: 8px;
}
.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  max-width: 36px;
  aspect-ratio: 1/1;

  gap: 5px;
  border-radius: 6px;
  background: var(--Base-200);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.heart,
.notification {
  color: var(--Base-50);
  font-size: 22px;
}
.heart {
  color: var(--Base-100);
}

@media only screen and (max-width: 1199px) {
  .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    padding-bottom: 15px;
    gap: 24px;
  }
  .headingAndInfoContainer {
    gap: 20px;
  }
  .bannerContainer {
    width: 100%;
    padding: 0;
  }
  .item {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .headingAndInfoContainer,
  .itemContainer,
  .bottom {
    width: 100%;
    padding: 0 17px;
  }
  .item {
    padding: 4px 0;
  }
  .header {
    padding: 8px 15px;
  }
  .timeContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
  }
  .bottom {
    gap: 20px;
  }
  .icons {
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 991px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
  .icon {
    width: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .eth {
    width: 20px;
  }
}
@media only screen and (max-width: 400px) {
  .bottom {
    padding: 12px;
  }
}
