.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.searchContainer {
  position: relative;
  background: var(--Base-400);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-border-dark);

  max-width: 420px;
  width: 100%;
  display: flex;
  padding-right: 10px;
  border-radius: 8px;
}

.searchContainer .icon {
  max-width: 24px;
}

.searchContainer input {
  color: var(--Base-0);
  height: 40px;

  padding: 23px 12px;
  border: none;
  font-size: 14px;
  width: 100%;
  font-weight: 500;

  outline: none;
  background: transparent;

  width: 100%;
}

.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}
.projects {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
@media only screen and (max-width: 1199px) {
  .projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .projects {
    grid-template-columns: repeat(1, 1fr);
    max-width: 500px;
  }
}
