.imgContainer {
  display: flex;

  align-items: center;
}
.img {
  max-width: 470px;
  width: 100%;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 46px;
}
.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
@media only screen and (max-width: 991px) {
  .myWrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .right {
    align-items: center;
  }
}
