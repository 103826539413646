.warningWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.warningContainer {
  display: flex;
  align-items: start;
  gap: 10px;
  border-radius: 12px;
  background: rgba(255, 158, 45, 0.1);
  padding: 12px 16px;
}

@media only screen and (max-width: 520px) {
  .warningContainer {
    padding: 12px;
  }
}
