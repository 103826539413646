.btn.sm {
  height: 36px;
  padding: 8px 16px 8px 16px;
  border-radius: 6px;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
}

.btn.md {
  height: 48px;
  padding: 12px 20px;
  border-radius: 8px;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
}

.btn.lg {
  height: 48px;
  padding: 6px 12px;
  border-radius: 8px;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
}

.btn {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  cursor: pointer;
  transition: 0.4s ease;
  text-align: center;
  font-size: 14px;
}

.btn:hover {
  transform: translateY(-3px);
}

.btn.btnPrimary {
  color: var(--Primary-Content);

  background: var(--Primary-Default);
}
.lightTheme.btn.btnPrimary {
  color: var(--white);
}

.btn.btnBlack {
  color: var(--Base-50);
  background: var(--Base-400);
}
.lightTheme.btn.btnBlack {
  border: 1px solid var(--color-border-dark);
}
.btn.btnMoreBlack {
  color: var(--Base-50);
  background: var(--Base-500);
}
.lightTheme.btn.btnMoreBlack {
  border: 1px solid var(--color-border-dark);
}
.btn.btnGray {
  color: var(--Base-0);
  background: var(--Base-200);
}
.lightTheme.btn.btnGray {
  background: var(--color-bg-dark-4);
}
.btnWarning {
  color: var(--Base-0);
  background: var(--color-red);
}
.btnGreen {
  color: var(--Base-0);
  background: var(--Success-Default);
}
.wFull {
  width: 100%;
}

.btn.btnTransparent {
  color: var(--Primary-Default);
  background: transparent;
  box-shadow: none;
}
.lightTheme .btn.btnTransparent {
  box-shadow: none;
}
.btn.iconBtn {
  padding-left: 8px;
  padding-right: 8px;
}
.mlAuto {
  margin-left: auto;
}
