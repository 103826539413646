.wrapper {
  border-radius: 12px;
  border: 1px solid var(--Base-200);
  background: var(--Base-400);
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 12px;
  }
}
