.stats {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 62px;
}

@media screen and (max-width: 1024px) {
  .stats {
    justify-content: space-between;
  }
}

@media screen and (max-width: 640px) {
  .stats {
    flex-direction: column;
  }
}

.stats .statsCard {
  display: flex;
  width: 250px;
  height: 100px;
  padding: 0px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(36, 43, 51, 0.8) 0%,
      rgba(36, 43, 51, 0.8) 100%
    ),
    rgba(36, 43, 51, 0.8);
}

@media screen and (max-width: 640px) {
  .stats .statsCard {
    width: 100%;
  }
}

.stats .statsCard .mainText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.stats .statsCard .light {
  color: rgba(255, 255, 255, 0.5);
}

.stats .statsCard .subText {
  color: rgba(255, 255, 255, 0.8);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 4px;
}

.stats .statsCard .iconText {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 12px;
  color: #e4f5ff;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
