.leftSide {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}
.heading {
  color: var(--Secondary-Content);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 28px;
  display: flex;
  padding: 2px 10px;
  align-items: center;
  gap: 10px;
  background: var(--Secondary-Default);
}
.title {
  color: var(--Base-0);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
}
.bottomImg {
  display: block;
}
.rightSide {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
}
.rightSide .title {
  display: flex;
  align-items: flex-end;

  gap: 5px;
}
.boxWrapper {
  display: flex;
  flex-direction: column;

  gap: 8px;
}
.box {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  border-radius: 20px;
  background: var(--Base-400);
  padding: 24px;
}
@media only screen and (max-width: 1199px) {
  .title {
    font-size: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .leftSide {
    align-items: center;
  }
  .heading {
    text-align: center;
  }
}
@media only screen and (max-width: 520px) {
  .title {
    font-size: 36px;
  }
  .rightSide {
    flex-wrap: wrap;
  }
  .box,
  .boxWrapper {
    width: 100%;
  }
  .rightSide .title {
    font-size: 28px;
  }
  .box {
    padding: 15px;
  }
}
