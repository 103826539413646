.progressBar {
  display: flex;
  align-items: center;

  width: 100%;
  background: var(--Base-200);

  border-radius: 10px;
  transition: background-color 0.5s ease-out;
}

.progress {
  height: 100%;
  background: var(--Primary-Default);
  border-radius: 10px;
  height: 12px;
  transition: width 0.5s ease-out;
  border-radius: 8px;
}
