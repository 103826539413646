.progressBar {
  display: flex;
  align-items: center;

  width: 100%;
  border-radius: 8px;
  border-radius: 10px;
  background: var(--Base-200);
}

.progress {
  height: 120%;
  background: var(--Primary-Default);
  border-radius: 200;
  height: 8px;
  transition: 0.5s ease-out;
  border-radius: 8px;
  position: relative;
}
.progress::before {
  content: attr(data-value);
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(80%, calc(-100% - 5px));
  background: var(--Base-500);
  color: var(--Primary-Default);
  text-align: right;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.progress::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  border: 1px solid var(--Base-0);
  border-radius: 10px;
  border: 2px solid var(--Primary-Content);
  background: var(--Primary-Default);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.26),
    0px 1px 1px 0px rgba(0, 0, 0, 0.23);
}
