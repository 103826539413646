.saleBox {
  border-radius: 20px;
  border: 1px solid var(--Base-300);
  background: var(--Base-400);
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.saleInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.progressText {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: 5px;
}
.contributeBox {
  border-radius: 12px;
  border: 1px solid var(--Base-300);
  background: var(--Base-500);
  border-radius: 12px;
  padding: 12px;

  gap: 15px;
  display: grid;
  grid-template-columns: 1.3fr 1fr;
}
.contributeActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.contribution {
  color: var(--Base-0);
  text-align: center;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 111.111%;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
}
.contribution::placeholder {
  color: var(--Base-0);
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.buttonContainer .button {
  width: 100%;
}

.contributorList {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 0;
  border-bottom: 1px solid var(--Base-300);
}
@media only screen and (max-width: 991px) {
  .saleBox {
    padding: 12px;
  }
}
@media only screen and (max-width: 520px) {
  .contributeBox {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 380px) {
  .saleBox {
    padding: 12px 10px;
  }
}
