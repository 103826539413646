.datePickerRoot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.datePickerRoot input {
  border-radius: 20px;

  width: 100%;
  flex-shrink: 0;
  background-color: transparent;

  outline: none;
  text-align: left;
  padding-right: 40px;

  border: none;
  text-transform: uppercase;
  width: 100%;
  color: var(--Base-0);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 155.556%;
}
.calendarIcon {
  color: var(--Base-0);
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .datePickerRoot {
    width: 100%;
  }

  .datePickerRoot input {
    width: 100%;
  }
}

.datePickerRoot input:placeholder {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.datePickerRoot img {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
}
@media only screen and (max-width: 520px) {
  .datePickerRoot input {
    font-size: 14px;
  }
}
