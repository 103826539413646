.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
  flex-shrink: 0;
}
.mlAuto {
  margin-left: auto;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--Base-300);
  border: 1px solid rgba(255, 255, 255, 0.05);

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  top: 50%;

  transform: translateY(-50%);
  background-color: var(--Base-0);
  opacity: 0.2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--Primary-Default);
}

input:disabled + .slider {
  opacity: 0.5;
  pointer-events: none;
}

input:checked + .slider::before {
  opacity: 1;
}

input:checked + .slider:before {
  -webkit-transform: translate(18px, -50%);
  -ms-transform: translate(18px, -50%);
  transform: translate(18px, -50%);
}
