.wrapper {
  display: flex;
  flex-direction: column;

  gap: 100px;
  padding-bottom: 100px;
}
.line {
  width: 100%;
  height: 2px;
  background: var(--Base-200);
}
@media only screen and (max-width: 520px) {
  .wrapper {
    gap: 50px;
    padding-bottom: 50px;
  }
}
