.wrapper {
  border-radius: 12px;
  border: 1px solid var(--Base-200);
  background: var(--Base-400);
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.addressContainer {
  padding-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  gap: 15px;
  border-bottom: 1px solid var(--Base-200);
}
.address {
  border-radius: 8px;
  background: var(--Base-500);
  padding: 12px 18px;
}
.addressText {
  color: var(--Base-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border: none;
  outline: none;
  text-align: center;
  background: transparent;
  width: 100%;
}
.addressContainer .button {
  height: auto;
  min-width: 100px;
}

.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
}
.bottomBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.text {
  color: var(--Base-0);
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 12px;
  }
  .addressContainer {
    grid-template-columns: 1fr;
  }
  .addressContainer .button {
    height: auto;
    font-size: 14px;
    padding: 10px 15px;
  }
  .address {
    font-size: 12px;
    padding: 12px 15px;
  }
  .addressText {
    font-size: 14px;
  }
  .buttonContainer .button {
    font-size: 14px;
    padding: 14px 12px;
    height: auto;
  }
}
