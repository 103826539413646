.heading {
  color: var(--Base-0);
  text-align: center;

  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 111.111%;
}

.left {
  text-align: left;
}
@media only screen and (max-width: 991px) {
  .mobileCenter {
    text-align: center;
  }
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 30px;
  }
}
