.right {
  display: flex;
  flex-direction: column;
  gap: 46px;
}
.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.imgContainer {
  display: flex;
  align-items: center;
}
.img {
  max-width: 480px;
  width: 100%;
}
.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
}
.item {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 58px;
  background: var(--Base-400);
  padding: 6px 24px 6px 6px;
}

@media only screen and (max-width: 991px) {
  .myWrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 767px) {
  .item .text {
    font-size: 12px;
  }
  .item .icon {
    max-width: 15px;
  }
  .img {
    max-width: 350px;
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .items {
    grid-template-columns: 1fr 1fr;
  }
  .item:last-child {
    grid-column: 1/-1;
  }
}
