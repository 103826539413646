.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.headingContainer {
  max-width: 792px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.container {
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 50px;
}
.boxWrapper {
  padding: 0 8px;
}
.box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid var(--Base-300);
  background: var(--Base-500);
  padding: 20px;
  border-radius: 20px;
}
.rating {
  display: flex;
  align-items: center;
  gap: 5px;
}
.star {
  color: var(--Secondary-Default);
}

.userContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.user {
  max-width: 56px;
}
.nameAndDesignation {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.nextButton,
.previousButton {
  margin: 0 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 50%;

  position: absolute;
  bottom: -40px;

  z-index: 500;
  background: transparent;
}
.nextButton {
  left: 0;
}
.previousButton {
  right: 0;
}

.arrow {
  color: var(--Base-0);
}
.activeButton {
  color: var(--Base-0);
  border: none;
}
@media only screen and (max-width: 991px) {
  .headingContainer {
    align-items: center;
  }
}
@media only screen and (max-width: 520px) {
  .user {
    max-width: 42px;
  }
}
